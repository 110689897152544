<template>
  <div>
    <CResume :access-id="accessId" />
  </div>
</template>

<script>
import CResume from "@/components/resume/CResume";

export default {
  name: "Resume",
  components: { CResume },
  props: {
    accessId: { type: String, default: "" },
  },
};
</script>
<style scoped>
.loading-indicator {
  font-size: 4rem;
  margin-top: 1rem;
}

iframe {
  width: calc(100% + 15px);
  height: 100vh;
  border: none;
}
</style>
