<template>
  <div>
    <div v-if="startedToOpenChat && !channelIdRequest.loaded" class="col-sm-12 text-center">
      <i class="fa fa-spinner fa-pulse fa-2x"></i>
    </div>
    <div v-if="channelIdRequest.loaded && channelIdRequest.error" class="col-sm-12 text-center mb-4">
      <BaseError
        :msg="'Beim öffnen des Chats ist ein Fehler aufgetreten: ' + channelIdRequest.error.message"
      ></BaseError>
    </div>
    <iframe :src="getResumePreviewUrl()"></iframe>
  </div>
</template>

<script>
import { services } from "@/main";
import BaseError from "@/components/base/BaseError";
import { GetChannelIdResult } from "@/services/message/MessageServiceResponses";
import { eventBus } from "@/eventbus";

export default {
  name: "CResume",
  components: { BaseError },
  props: {
    accessId: { type: String, required: true },
  },
  data() {
    return {
      channelIdRequest: new GetChannelIdResult(),
      startedToOpenChat: false,
    };
  },
  computed: {
    channelId: function() {
      return this.channelIdRequest.apiData.channelId;
    },
  },
  watch: {
    "channelIdRequest.loaded": function() {
      if (this.channelIdRequest.loaded && !this.channelIdRequest.error) {
        this.openChat();
      }
    },
  },
  created() {},
  mounted() {
    window.addEventListener(
      "message",
      (event) => {
        if (event.origin === window.RESUME_API_URL) {
          if (event.data === "openchat") {
            this.startedToOpenChat = true;
            this.channelIdRequest = services.messageService.getChannelId(this.accessId, this.accessId);
          }
        }
      },
      false
    );
  },
  methods: {
    getResumePreviewUrl: function() {
      return (
        window.RESUME_API_URL +
        "/resume/resume_preview_by_access_id/" +
        this.accessId +
        "/?auth_token=" +
        services.authenticationService.getIDToken()
      );
    },
    openChat: function() {
      eventBus.$emit("openChatComponent", { channelId: this.channelId });
    },
  },
};
</script>
<style scoped>
.loading-indicator {
  font-size: 4rem;
  margin-top: 1rem;
}

iframe {
  width: 100%;
  height: 92vh;
  border: none;
}
</style>
